import { useApiManual } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'

import { LocationsFormData } from './types'
import { buildLocationsMap } from './utils'

export const useCardLocationsSubmit = () => {
  const { requestThrows: linkLocationsInternal } = useApiManual(useActivationApi().linkAdGroupLocations)

  const linkLocations = async ({ locations, initialLocations, adGroupId }: {
    locations: LocationsFormData
    initialLocations: LocationsFormData
    adGroupId: number
  }) => {
    const initialLocationsMap = buildLocationsMap(initialLocations)
    const locationsMap = buildLocationsMap(locations)

    const toAdd = locations
      .filter((location) => {
        const linkedLocation = initialLocationsMap.get(location.type)?.get(location.id)

        return !linkedLocation || linkedLocation.enabled !== location.enabled
      })
      .map(location => ({
        locationId: location.enabled ? location.id : -location.id,
        locationName: location.name,
        locationSubType: location.type,
      }))

    const toRemove = initialLocations
      .filter((location) => {
        const selectedLocation = locationsMap.get(location.type)?.get(location.id)

        return !selectedLocation
      })
      .map(location => ({
        locationId: location.id,
        locationName: location.name,
        locationSubType: location.type,
      }))

    if (toAdd.length > 0 || toRemove.length > 0) {
      await linkLocationsInternal({
        toAdd,
        toRemove,
        adGroupId,
      })
    }
  }

  return { linkLocations }
}
