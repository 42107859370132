import { createInjectionState } from '@lasso/shared/utils'
import { LocationSubType } from '@lasso/api-activation/activation'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, reactive, ref, watch } from 'vue'

import { UseFormAddonsContext } from '@lasso/shared/hooks'

import { useLocationLists } from './useLocationLists'
import { useSelectedLocations } from './useSelectedLocations'
import { CardLocationsFormData, NormalizedLocation } from './types'
import { normalizeSelectedLocation } from './utils'

export const [useProvideCardLocations, useCardLocations] = createInjectionState(({
  isCardEnabled,
  adGroupId,
  accountId,
  useFieldModels,
  resetField,
  getId,
}: {
  isCardEnabled: RefOrGetter<boolean>
  isDirectSold: boolean
  adGroupId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
} & UseFormAddonsContext<CardLocationsFormData>) => {
  const { locations } = useFieldModels(['locations'])

  const setInitialLocations = (locations: NormalizedLocation[]) => {
    return resetField('locations', { value: locations })
  }

  const locationType = ref(LocationSubType.STATE)
  const visitedTabs = reactive(new Set<LocationSubType>())

  watch(locationType, (value) => {
    visitedTabs.add(value)
  }, { immediate: true })

  const {
    searchQueries,
    locationLists,
    isListLoading,
    isListEmpty,
    isListNoToggle,
    getListPlaceholder,
    getListPlaceholderIcon,
    getListError,
    requestList,
  } = useLocationLists({ isCardEnabled, accountId, visitedTabs })

  const {
    locationsGrouped,
    loading,
    error,
    request,
    onData,
    isLocationSelected,
    setLocationSelected,
    setLocationsSelected,
    clearLocations,
    isLocationEnabled,
    setLocationEnabled,
    getLocationChip,
  } = useSelectedLocations({ isCardEnabled, locations, adGroupId })

  onData(({ data }) => {
    setInitialLocations(data?.map(normalizeSelectedLocation) ?? [])
  })

  // Select all unselected postal codes when the list is loaded
  watch(() => locationLists[LocationSubType.POSTAL_CODE], (list) => {
    setLocationsSelected(list, true)
  })

  return {
    searchQueries,
    locationType,
    loading,
    error,
    request,
    locations,
    locationsGrouped,
    locationLists,
    isListLoading,
    isListEmpty,
    isListNoToggle,
    getListPlaceholder,
    getListPlaceholderIcon,
    getListError,
    requestList,
    isLocationSelected,
    setLocationSelected,
    clearLocations,
    isLocationEnabled,
    setLocationEnabled,
    getLocationChip,
    getId,
  }
})
