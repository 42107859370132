import { array, mixed } from 'yup'

import { CardLocationsSchemaShape, NormalizedLocation } from './types'

export const buildCardLocationsSchema = ({ isCardEnabled }: { isCardEnabled: boolean }): CardLocationsSchemaShape => {
  const baseSchema = array(mixed<NormalizedLocation>().default({} as NormalizedLocation)).default([])

  if (!isCardEnabled) {
    return { locations: baseSchema }
  }

  return {
    locations: baseSchema,
  }
}
